const state = {
  serverUrl: "https://backend-api-6krx.onrender.com", //"http://localhost:5001"
  browserUrl: "https://www.flux-os.com", //"http://localhost:8080"
};

const mutations = {};

const actions = {};

const getters = {
  serverUrl: (state) => state.serverUrl,
  browserUrl: (state) => state.browserUrl,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
