<template>
  <!-- Unauthenticated -->
  <nav class="bg-white border-gray-200 dark:bg-gray-900">
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
    >
      <router-link
        tag="a"
        to="/"
        class="flex items-center space-x-3 rtl:space-x-reverse"
      >
        <img
          src="@/assets/img/png/black_logo_no_background.png"
          class="h-8"
          alt="Flux Logo"
        />
      </router-link>
      <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
        <router-link
          to="/login"
          tag="button"
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          v-if="$store.getters['auth/authenticated'] == false"
        >
          Get started
        </router-link>
        <router-link
          to="/app"
          tag="button"
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center space-x-2"
          v-if="$store.getters['auth/authenticated'] == true"
        >
          <span>Dashboard</span>
          <svg
            class="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 12H5m14 0-4 4m4-4-4-4"
            />
          </svg>
        </router-link>

        <button
          data-collapse-toggle="navbar-cta"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-cta"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>
      <div
        class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
        id="navbar-cta"
      >
        <ul
          class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
        >
          <li>
            <router-link
              to="/"
              tag="a"
              :class="{
                'text-white bg-blue-700 md:text-blue-700 md:dark:text-blue-500':
                  $route.name == 'home',
                'text-gray-900 ': $route.name == 'home',
              }"
              class="block py-2 px-3 md:p-0 rounded md:bg-transparent"
              >Home</router-link
            >
          </li>
          <li>
            <a
              href="https://docs.flux-os.com/introduction"
              target="_blank"
              class="block py-2 px-3 md:p-0 rounded md:bg-transparent"
              >Docs</a
            >
          </li>
          <li>
            <router-link
              tag="a"
              to="/contact"
              :class="{
                'text-white bg-blue-700 md:text-blue-700 md:dark:text-blue-500':
                  $route.name == 'contact',
                'text-gray-900 ': $route.name == 'contact',
              }"
              class="block py-2 px-3 md:p-0 rounded md:bg-transparent"
              >Contact</router-link
            >
          </li>
          <li>
            <router-link
              to="/login"
              :class="{
                'text-white bg-blue-700 md:text-blue-700 md:dark:text-blue-500':
                  $route.name == 'login',
                'text-gray-900 ': $route.name == 'login',
              }"
              class="block py-2 px-3 md:p-0 rounded md:bg-transparent"
              aria-current="page"
              v-if="$store.getters['auth/authenticated'] == false"
              >Get Started</router-link
            >
            <router-link
              to="/app"
              :class="{
                'text-white bg-blue-700 md:text-blue-700 md:dark:text-blue-500':
                  $route.name == 'app-home',
                'text-gray-900 ': $route.name == 'app-home',
              }"
              class="block py-2 px-3 md:p-0 rounded md:bg-transparent"
              aria-current="page"
              v-if="$store.getters['auth/authenticated'] == true"
              >Dashboard</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { StytchB2BHeadlessClient } from "@stytch/vanilla-js/b2b/headless";

export default {
  name: "NavSection",
  data: function () {
    return {
      organizations: [],
    };
  },
  mounted: function () {
    if (this.$store.getters["auth/authenticated"]) {
      this.getOrganizations();
    }
  },
  methods: {
    getOrganizations: async function () {
      const stytch = new StytchB2BHeadlessClient(
        this.$store.getters["auth/stytch_pk"]
      );
      const { email_address, discovered_organizations } =
        await stytch.discovery.organizations.list();
      console.log({ email_address, discovered_organizations });
      this.organizations = discovered_organizations;
    },
    logout: function () {
      const stytch = new StytchB2BHeadlessClient(
        this.$store.getters["auth/stytch_pk"]
      );
      stytch.session.revoke();
    },
    exchange: function (organization_id) {
      const stytch = new StytchB2BHeadlessClient(
        this.$store.getters["auth/stytch_pk"]
      );
      stytch.session.exchange({
        organization_id: organization_id,
        session_duration_minutes: 60,
      });
    },
  },
};
</script>
