import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

// Landing Views
import HomeView from "../views/landing/HomeView.vue";
import ContactView from "../views/landing/ContactView.vue";
import ProductView from "../views/landing/ProductView.vue";
import BlogView from "../views/landing/BlogView.vue";
import CareersView from "../views/landing/CareersView.vue";

// Main Views
import AppView from "../views/main/AppView.vue";
import ProfileView from "../views/main/ProfileView.vue";
import InboxView from "../views/main/InboxView.vue";
import SupportView from "../views/main/SupportView.vue";

// Authentication Views
import LoginView from "../views/auth/LoginView.vue";
import LogoutView from "../views/auth/LogoutView.vue";

// Legal Views
import PoliciesView from "../views/legal/PoliciesView.vue";
import TermsView from "../views/legal/TermsView.vue";
import PrivacyView from "../views/legal/PrivacyView.vue";
import LicenseAgreementView from "../views/legal/LicenseAgreementView.vue";

// Status Pages
import ChangelogView from "../views/status/ChangelogView.vue";

// Marketing Pages
import BalanceSheetView from "../views/marketing/BalanceSheetView.vue";
import DealSheetView from "../views/marketing/DealSheetView.vue";
import DealView from "../views/marketing/DealView.vue";
import MarketDataView from "../views/marketing/MarketDataView.vue";
import NominationsView from "../views/marketing/NominationsView.vue";

// Search Pages
import UsersView from "../views/search/UsersView.vue";
import OrganizationsView from "../views/search/OrganizationsView.vue";
import MarketersView from "../views/search/MarketersView.vue";
import ProducersView from "../views/search/ProducersView.vue";
import CarriersView from "../views/search/CarriersView.vue";

// Accounting Pages
import FinancialsView from "../views/accounting/FinancialsView.vue";
import InvoicingView from "../views/accounting/InvoicingView.vue";
import ReconciliationView from "../views/accounting/ReconciliationView.vue";

// Optimization Pages
import AlgorithmsView from "../views/optimization/AlgorithmsView.vue";
import AlertsView from "../views/optimization/AlertsView.vue";
import HistoryView from "../views/optimization/HistoryView.vue";

// Error Views
import PageNotFoundView from "../views/errors/PageNotFoundView.vue";
import InternalError from "../views/errors/InternalErrorView.vue";
import UnauthorizedAccessView from "../views/errors/UnauthorizedAccessView.vue";

Vue.use(VueRouter);

const routes = [
  // Landing Views
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      authenticated: false,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      title: "Contact Us",
      authenticated: false,
    },
  },
  {
    path: "/product/:slug",
    name: "product",
    component: ProductView,
    meta: {
      title: "Coming Soon",
      authenticated: false,
    },
  },
  {
    path: "/careers",
    name: "careers",
    component: CareersView,
    meta: {
      title: "Careers",
      authenticated: false,
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
    meta: {
      title: "Blog",
      authenticated: false,
    },
  },
  // Auth Pages
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Get Started",
      authenticated: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
    meta: {
      title: "Logging out...",
      authenticated: false,
    },
  },
  // Legal Pages
  {
    path: "/terms",
    name: "terms",
    component: TermsView,
    meta: {
      title: "Terms of Use",
      authenticated: false,
    },
  },
  {
    path: "/license-agreement",
    name: "license-agreement",
    component: LicenseAgreementView,
    meta: {
      title: "License Agreement",
      authenticated: false,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
    meta: {
      title: "Privacy Policy",
      authenticated: false,
    },
  },
  {
    path: "/policies",
    name: "policies",
    component: PoliciesView,
    meta: {
      title: "Policies",
      authenticated: false,
    },
  },
  // Main Views
  {
    path: "/app",
    name: "app",
    children: [
      {
        path: "",
        name: "app-home",
        component: AppView,
        meta: {
          title: "Dashboard",
          authenticated: true,
        },
      },
      {
        path: "profile",
        name: "profile",
        component: ProfileView,
        meta: {
          title: "Profile",
          authenticated: true,
        },
      },
      {
        path: "inbox",
        name: "inbox",
        component: InboxView,
        meta: {
          title: "Inbox",
          authenticated: true,
        },
      },
      {
        path: "support",
        name: "support",
        component: SupportView,
        meta: {
          title: "Support",
          authenticated: true,
        },
      },
      // Status Pages
      {
        path: "changelog",
        name: "changelog",
        component: ChangelogView,
        meta: {
          title: "Changelog",
          authenticated: true,
        },
      },
      // Optimization Pages
      {
        path: "optimization",
        name: "optimization",
        children: [
          {
            path: "algorithms",
            name: "algorithms",
            component: AlgorithmsView,
            meta: {
              title: "Algorithms",
              authenticated: true,
            },
          },
          {
            path: "alerts",
            name: "alerts",
            component: AlertsView,
            meta: {
              title: "Alerts",
              authenticated: true,
            },
          },
          {
            path: "history",
            name: "history",
            component: HistoryView,
            meta: {
              title: "History",
              authenticated: true,
            },
          },
        ],
      },
      // Accounting Pages
      {
        path: "accounting",
        name: "accounting",
        children: [
          {
            path: "financials",
            name: "financials",
            component: FinancialsView,
            meta: {
              title: "Financials",
              authenticated: true,
            },
          },
          {
            path: "invoicing",
            name: "invoicing",
            component: InvoicingView,
            meta: {
              title: "Invoicing",
              authenticated: true,
            },
          },
          {
            path: "reconciliation",
            name: "reconciliation",
            component: ReconciliationView,
            meta: {
              title: "Reconciliation",
              authenticated: true,
            },
          },
        ],
      },
      // Search Pages
      {
        path: "search",
        name: "search",
        children: [
          {
            path: "users",
            name: "users",
            component: UsersView,
            meta: {
              title: "Search Users",
              authenticated: true,
            },
          },
          {
            path: "carriers",
            name: "carriers",
            component: CarriersView,
            meta: {
              title: "Search Carriers",
              authenticated: true,
            },
          },
          {
            path: "marketers",
            name: "marketers",
            component: MarketersView,
            meta: {
              title: "Search Marketers",
              authenticated: true,
            },
          },
          {
            path: "organizations",
            name: "organizations",
            component: OrganizationsView,
            meta: {
              title: "Search Organizations",
              authenticated: true,
            },
          },
          {
            path: "producers",
            name: "producers",
            component: ProducersView,
            meta: {
              title: "Search Producers",
              authenticated: true,
            },
          },
        ],
      },
      {
        path: "marketing",
        name: "marketing",
        children: [
          {
            path: "balance-sheet",
            name: "balance-sheet",
            component: BalanceSheetView,
            meta: {
              title: "Balance Sheet",
              authenticated: true,
            },
          },
          {
            path: "deal-sheet",
            name: "deal-sheet",
            component: DealSheetView,
            meta: {
              title: "Deal Sheet",
              authenticated: true,
            },
          },
          {
            path: "deal/:id",
            name: "deal",
            component: DealView,
            meta: {
              title: "Deal",
              authenticated: true,
            },
          },
          {
            path: "market-data",
            name: "market-data",
            component: MarketDataView,
            meta: {
              title: "Market Data",
              authenticated: true,
            },
          },
          {
            path: "nominations",
            name: "nominations",
            component: NominationsView,
            meta: {
              title: "Nominations",
              authenticated: true,
            },
          },
        ],
      },
    ],
  },
  // Error Pages
  {
    path: "/error",
    name: "500",
    component: InternalError,
    meta: {
      title: "Internal Error",
      authenticated: false,
    },
  },
  {
    path: "/unauthorized",
    name: "401",
    component: UnauthorizedAccessView,
    meta: {
      title: "Unauthorized Access",
      authenticated: false,
    },
  },
  {
    path: "*",
    name: "404",
    component: PageNotFoundView,
    meta: {
      title: "Page Not Found",
      authenticated: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(from);
  console.log(to);
  if (to.meta.authenticated == true) {
    const authenticated = store.getters["auth/authenticated"];
    if (authenticated == false) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    //Set Title Variable
    var title = to.meta.title + " | Flux OS";

    //Set Document Title
    document.title = title;
  });
});

export default router;
