<template>
  <div id="stytch-container">
    <div id="stytch"></div>
  </div>
</template>

<script>
import { StytchB2BUIClient } from "@stytch/vanilla-js/b2b";
import { mapState } from "vuex";

export default {
  name: "LoginView",
  computed: {
    ...mapState("auth", ["stytch_pk"]),
  },
  mounted: function () {
    const stytch = new StytchB2BUIClient(this.stytch_pk);

    // Render prebuilt UI
    stytch.mount({
      elementId: "#stytch",
      config: {
        authFlowType: "Discovery",
        products: ["emailMagicLinks"],
        emailMagicLinksOptions: {
          discoveryRedirectURL:
            this.$store.getters["app/browserUrl"] + "/login",
        },
        sessionOptions: {
          sessionDurationMinutes: 60,
        },
        disableCreateOrganization: true,
      },
      styles: {
        container: {
          borderColor: "white",
        },
        colors: {
          primary: "#000",
        },
        inputs: {
          borderColor: "rgb(209 213 219 / 1)",
        },
        buttons: {
          primary: {
            backgroundColor: "#000000",
            textColor: "white",
            borderColor: "#000000",
            borderRadius: "0.5rem",
          },
        },
      },
      callbacks: {
        onEvent: ({ type, data }) => {
          console.log(type, data);
        },
        onError: (data) => {
          console.log(data);
        },
      },
    });
    // Function to hide the grandparent div of the target image
    const hideStytchLogo = () => {
      document
        .querySelectorAll('img[alt="Powered by Stytch"]')
        .forEach((img) => {
          const targetDiv = img.closest("div.sc-dPyBCJ");
          if (targetDiv) {
            targetDiv.style.display = "none";
          }
        });
    };

    // Initially hide if the element already exists
    hideStytchLogo();

    // Set up a MutationObserver to watch for changes
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(() => {
        hideStytchLogo(); // Check and hide the element on every mutation
      });
    });

    // Start observing the document body for changes in child nodes and subtree
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Stop observing when the component is destroyed
    this.$once("hook:beforeDestroy", () => {
      observer.disconnect();
    });
  },
};
</script>
<style>
#stytch-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Full viewport height */
  background-color: white !important;
}

#stytch {
  background-color: white !important; /* Example background color */
}
.dTIkNW {
  border: 0 solid !important;
}
</style>
